<template>
    <div class="form-field">
        <div class="form-row">
            <img :src="imageURL" alt="Example Image" class="example-image" />
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const imageURL = 'https://i.imgur.com/J0OMrDU.png';

        return {
            imageURL
        };
    }
}
</script>

<style scoped>
body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.form-row {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

.example-image {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>

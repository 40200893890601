<template>
  <div>
    <div class="header-image"></div>

    <div class="tab-bar">
      <button @click="currentTab = 'script'" :class="{ active: currentTab === 'script' }">Retail script</button>
      <button @click="currentTab = 'curbside'" :class="{ active: currentTab === 'curbside' }">Curbside SOS Pre-Auth</button>
      <!--  <button @click="currentTab = 'curbside_retail'" :class="{ active: currentTab === 'curbside_retail' }">Curbside SOS Retail</button>  -->
      <button @click="currentTab = 'battery'" :class="{ active: currentTab === 'battery' }">Battery Script</button>
      <button @click="currentTab = 'addon'" :class="{ active: currentTab === 'addon' }">Retail add-ons</button>
      <button @click="currentTab = 'location'" :class="{ active: currentTab === 'location' }">Location link</button>
      <button @click="currentTab = 'comp'" :class="{ active: currentTab === 'comp' }">Addt'l comp</button>
      <button @click="currentTab = 'db'" :class="{ active: currentTab === 'db' }">Job history</button>
      <button v-if="isStaging" @click="currentTab = 'editor'" :class="{ active: currentTab === 'editor' }">Script editor</button>
      <button @click="currentTab = 'map'" :class="{ active: currentTab === 'map' }">Coverage map</button>
      <button v-if="isAdmin" @click="currentTab = 'form'" :class="{ active: currentTab === 'form' }">Backup retail form</button>
      <button v-if="isAdmin" @click="currentTab = 'guest'" :class="{ active: currentTab === 'guest' }">Guest users</button>
    </div>
    
    <CallScript v-if="currentTab === 'script'" />
    <CurbsideScript v-if="currentTab === 'curbside'" />
    <CurbsideRetailScript v-if="currentTab === 'curbside_retail'" />
    <BatteryScript v-if="currentTab === 'battery'" />
    <AddonComponent v-if="currentTab === 'addon'" />
    <LocationComponent v-if="currentTab === 'location'" />
    <CompComponent v-if="currentTab === 'comp'" />
    <DbComponent v-if="currentTab === 'db'" />
    <EditorComponent v-if="currentTab === 'editor'" />
    <MapComponent v-if="currentTab === 'map'" />
    <FormComponent v-if="currentTab === 'form'" />
    <GuestComponent v-if="currentTab === 'guest'" />
  </div>
</template>

<script>
import FormComponent from '../components/FormComponent.vue';
import CallScript from '../components/CallScript.vue';
import CurbsideScript from '../components/CurbsideCallScript.vue';
import CurbsideRetailScript from '../components/CurbsideRetailCallScript.vue';
import BatteryScript from '../components/BatteryProgramScript.vue';
import EditorComponent from '../components/EditorComponent.vue';
import MapComponent from '../components/MapComponent.vue';
import AddonComponent from '../components/AddonComponent.vue';
import DbComponent from '../components/DbComponent.vue';
import LocationComponent from '../components/LocationComponent.vue';
import CompComponent from '../components/CompComponent.vue';
import GuestComponent from '../components/GuestComponent.vue';
import { ref } from 'vue';

export default {
  components: {
    FormComponent,
    CallScript,
    CurbsideScript,
    CurbsideRetailScript,
    BatteryScript,
    EditorComponent,
    AddonComponent,
    MapComponent,
    DbComponent,
    LocationComponent,
    CompComponent,
    GuestComponent
  },
  setup() {
    const currentTab = ref('script');
    const isStaging = window.location.hostname.includes('staging');
    const isAdmin = ref(false);

    const checkAuthStatus = async () => {
      try {
        const response = await fetch('/authed');
        if (response.status === 200) {
          const data = await response.json();
          isAdmin.value = data.isAdmin;
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        window.location.reload();
      }
    };

    checkAuthStatus();
    setInterval(checkAuthStatus, 1800000);

    return {
      currentTab,
      isStaging,
      isAdmin
    };
  }
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  background-color: #7EB2CF;
}

.header-image {
  width: 100%; 
  height: 120px; 
  position: relative;
  overflow: hidden;
  background-image: url('../assets/header-image.png');
  background-size: cover;
  background-blend-mode: overlay;
}

.tab-bar {
  display: flex;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  justify-content: center;
  color: #fff;
  margin-top: 10px;
}

.tab-bar button {
  color: #fff;
  background-color: #6c8192;
  border-radius: 5px;
  border: 1px solid #356083;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-bar button:hover {
  background-color: #3b99e6;
}

.tab-bar button.active {
  background-color: #4682b4;
}
</style>